<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading">
      <template #rightSlot>
        <div class="d-flex align-center">
          <v-btn depressed color="primary accent-4" class="white--text mr-3" @click="downloadReceipt()">下載收據</v-btn>
        </div>
      </template>
    </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">訂單詳情</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <label class="mb-4">訂單編號</label>
              <div class="colorBlack--text">#{{ $Formatter.pad(id, 4) }}</div>
            </v-col>
            <v-col cols="12" md="6">
              <label class="mb-4">建立日期</label>
              <div class="colorBlack--text">{{ $Formatter.displayCreateDate(formData.create_date, false) }}</div>
            </v-col>
            <v-col cols="12" md="6">
              <label class="mb-4">用戶名稱</label>
              <div class="colorBlack--text">{{ studentName }}</div>
            </v-col>
            <v-col cols="12" md="6">
              <label class="mb-4">電話</label>
              <div class="colorBlack--text">{{ studentPhone }}</div>
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="訂單狀態"
                :fieldValue.sync="formData.order_status"
                :options="$orderStatusOptions"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-input
                label="價錢( HK$)/ 堂"
                :fieldValue.sync="formData.lesson_price"
                required
                type="number"
                :customRules="[v => ($validate.isNumber(v) && parseFloat(v) > 0) || '價錢必須大於0']"
                :readonly="initData.order_status !== 'awaiting_payment'"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="付款方式"
                :fieldValue.sync="formData.payment_method"
                :options="paymentOptions"
                required
                :readonly="initData.order_status !== 'awaiting_payment'"
              />
            </v-col>
          </v-row>

          <div class="d-flex justify-end mb-2" style="font-size: 16px;" v-if="this.couponData">
            折扣：-${{ $Formatter.separator(discount) }}
          </div>
          <div class="d-flex justify-end" style="font-size: 18px; font-weight: 500;">
            總額：${{ $Formatter.separator(orderTotal) }}
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-text>
          <div class="d-flex align-center justify-space-between">
            <v-card-title class="page-title">課堂</v-card-title>
          </div>

          <datatable :tableHeaders="tableHeaders" :tableData="tableData" disablePagination disableFooter disableShadow>
            <template #item.subject="{item}">
              {{ item.subject | formatSubject }}
            </template>
          </datatable>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">
        儲存
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'OrderDetail',
  components: {
    FormInput,
    FormSelect,
    Datatable,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    orderTotal() {
      if (this.$validate.regexFloating(this.formData.lesson_price) && this.lessonCount) {
        let amount = parseFloat(this.formData.lesson_price) * this.lessonCount

        if (this.couponData) {
          if (this.couponData.discount_type === 'percentage') {
            amount = amount * (100 - this.couponData.discount_value) * 0.01
          } else if (this.couponData.discount_type === 'price') {
            amount -= this.couponData.discount_value
          }
        }

        return amount
      }

      return 0
    },
    discount() {
      if (this.$validate.regexFloating(this.formData.lesson_price) && this.lessonCount && this.couponData) {
        let amount = parseFloat(this.formData.lesson_price) * this.lessonCount
        if (this.couponData.discount_type === 'percentage') {
          return amount * this.couponData.discount_value * 0.01;
        } else if (this.couponData.discount_type === 'price') {
          return this.couponData.discount_value;
        }
      }

      return 0
    },
  },
  data: () => ({
    formData: {
      order_status: '',
      lesson_price: '',
      payment_method: '',
      create_date: '',
      student_id: null,
    },
    initData: {
      order_status: '',
    },
    studentName: '',
    studentPhone: '',
    lessonCount: 0,
    couponData: null,

    tableHeaders: [
      { text: '科目', value: 'subject' },
      { text: '堂數 ', value: 'lesson_count' },
      { text: '價錢( HK$)/ 堂 ', value: 'lesson_price' },
    ],
    tableData: [],
    paymentOptions: [
      { text: '信用卡', value: 'credit_card' },
      { text: '轉帳', value: 'bank_transfer' },
    ],
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetOrderById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key]) && key !== 'subject') {
            this.$set(this.formData, key, data[key])
          }
        }

        this.initData.order_status = data.order_status;

        this.getStudent()

        this.lessonCount = data.course_timetable.length
        this.tableData.push({
          subject: data.subject.length ? data.subject[0] : '',
          lesson_count: data.course_timetable.length,
          lesson_price: data.lesson_price,
        })

        if (data.promo_code_data && typeof data.promo_code_data === 'object' && data.promo_code_data.code) {
          this.couponData = data.promo_code_data
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'OrderList',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'OrderList',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async getStudent() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetStudentById(this.formData.student_id, user.id, user.token)
        this.studentName = data.name
        this.studentPhone = data.phone
      } catch {}
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }

      let payload = {
        order_status: this.formData.order_status,
      }

      if (this.initData.order_status === 'awaiting_payment') {
        payload['payment_method'] = this.formData.payment_method;
        payload['lesson_price'] = parseFloat(this.formData.lesson_price);
        payload['total'] = this.orderTotal;
      }

      const user = this.getCurrentUserData()
      this.$store.dispatch('setLoading', true)
      try {
        await this.$Fetcher.UpdateOrder(this.id, payload, user.id, user.token)
        this.$store.dispatch('setDataIsUpdated', false)
        this.$store.dispatch('toggleSnack', {
          type: 'success',
          message: '儲存成功',
          refresh: true,
        })
      } catch (err) {
        this.setAlert({
          title: '儲存失敗',
          message: err,
          type: 'error',
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },

    async downloadReceipt() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }
      const user = this.getCurrentUserData()
      this.$store.dispatch('setLoading', true)

      try {
        const data = await this.$Fetcher.GetOrderReceipt(this.id, user.id, user.token)
        window.open(`${this.$mediaPath}${data}`, '_blank')
      } catch (err) {
        this.setAlert({
          title: '下載失敗',
          message: err,
          type: 'error',
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    await this.getDetail()
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
